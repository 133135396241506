@import "variables.scss";
@import "mixins.scss";

//Define colors
$color-bg: $color-tint;
$color-main: $color-white;

body {
	color: $color-main;
	background: $color-bg;
}

#login {
	width: 320px;

	@include responsive(mobile) {
		width: 360px;
	}
}

.login h1 a {
	height: 80px !important;
	background-size: 80px !important;
}

.login form {
	border: none;
	box-shadow: none;
	background: $color-bg;
}

.login label {
	color: $color-main;
	font-size: 12px;
}

#nav, #backtoblog {
	display: none;
}

.login input[type=text], .login input[type=password], #login input[type=tel] {
	background: $color-bg;
	border: none;
	border-bottom: 1px solid $color-main;
	color: $color-main;
	box-shadow: none;
	font-weight: normal;
	font-family: 'Courier', 'Courier New', sans-serif;

	&:focus {
		outline: none;
		box-shadow: none;
	}
}

 input[type=checkbox] {
	border: 1px solid $color-bg;
	background: $color-bg;

	&:checked:before {
		color: $color-bg;
	}

	&:focus {
		border: 1px solid $color-bg;
		box-shadow: none;
	}
}

#wp-submit, #submit {
	background: $color-bg;
	color: $color-main;
	border: 1px solid $color-main;
	border-radius: 4px;
	text-shadow: none;
	font-size: 12px;
	box-shadow: none;
	margin-top: 20px;
	width: 100%;

	&:hover {
		color: $color-bg;
		background: $color-main;
	}
}

.wp-core-ui .button-group.button-large .button, .wp-core-ui .button.button-large {
	height: 40px;
}

.login #login_error {
	border: none;
	background: $color-bg;
	color: $color-main;
	box-shadow: none;

	a {
		color: $color-main;

		&:hover {
			color: $color-bg;
		}
	}
}

.login .message {
	border: none;
	background: $color-bg;
	color: $color-main;
	box-shadow: none;
	text-align: center;

}

a:focus {
	box-shadow: none;
}

.itsec-backup-methods {
	border: none !important;
	display: none !important;

	ul {
		list-style: none !important;
		padding: 0;
		margin: 0 !important;
	}

	a {
		color: $color-main;
		text-decoration: none !important;
	}
}
