@use 'sass:math';

@mixin responsive($width) {
	@if $width == mobile {
		@media only screen and (min-width: $width-mobile) {
			@content;
		}
	} @else if $width == landscape {
		@media only screen and (min-width: $width-landscape) {
			@content;
		}
	} @else if $width == tablet {
		@media only screen and (min-width: $width-tablet) {
			@content;
		}
	} @else if $width == desktop {
		@media only screen and (min-width: $width-desktop) {
			@content;
		}
	} @else if $width == widedesktop {
		@media only screen and (min-width: $width-wide) {
			@content;
		}
	}
}

@mixin clearfix {
	display: table;
	content: '';
	clear: both;
}

@mixin input {
	width: 100%;
	border: none;
	border-bottom: 1px solid $color-coal;
	font-size: ms(0);
	padding: math.div($base, 2) 0;
	font-family: 'fira-sans-2', sans-serif;
	color: $color-coal;
	font-size: ms(0);
	font-weight: $font-weight-normal;
	line-height: $line-height;
	-webkit-appearance: none;
	border-radius: 0;

	&:focus {
		border-bottom-color: $color-tint;
	}
}

@mixin button($cmain, $cbg, $cmainhover: $cbg, $cbghover: $cmain) {
	border: 1px solid $cmain;
	color: $cmain;
	background: $cbg;
	border-radius: 0;
	padding: ($base/4) ($base);
	font-size: ms(0);
	font-variant-caps: all-small-caps;
	font-feature-settings: 'smcp' 1, 'c2sc' 1;
	text-decoration: none;
	display: inline-block;
	font-weight: $font-weight-normal;

	@include responsive(tablet) {
		width: auto;
	}

	&:hover {
		background: $cbghover;
		color: $cmainhover;
		border-color: $cbghover;
		cursor: pointer;
	}

	&:disabled:hover {
		background: $cbg;
		color: $cmain;
		border-color: $cmain;
		cursor: not-allowed;
	}
}

@mixin setcolumns(
	$desktopcolumns,
	$mobilecolumns: 12,
	$tabletcolumns: $desktopcolumns
) {
	@include grid-column($mobilecolumns, $neat-grid-mobile);

	@include responsive(tablet) {
		@include grid-column($tabletcolumns);
	}
	@include responsive(desktop) {
		@include grid-column($desktopcolumns);
	}
}

@mixin col-wrapper {
	width: $width-full;
	margin: 0 auto;
	max-width: $width-max;
	margin-bottom: $spacing;
	@include grid-container($neat-grid);
}

@mixin col-full {
	width: $width-full;
	margin: 0 auto;
	@include grid-container($neat-grid);
	margin-bottom: $spacing;
}
